<template>

  <div style="text-align:center; background-color: #1a1a1a; padding: 2px;">
    <h6 class="text-white"><strong>Please note : </strong> We are opened from Monday to Saturday</h6>
  </div>
	
	<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <router-link to="/" class="navbar-brand">
    	<img src="/images/logo2.png" class="logo">
    </router-link>


    <div v-if="is_mobile">
        <router-link to="/cart" class="btn btn-lg"> <i class="bi bi-cart"></i> <sup v-if="this.$store.state.final_total_qty" class="color-1"> {{this.$store.state.final_total_qty}} </sup> <sup v-if="!this.$store.state.final_total_qty">0</sup> </router-link>
        <router-link class="btn btn-1" to="/track-order"> <i class="bi bi-truck"></i> Track Order</router-link>
      </div>


    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>


    <div class="collapse navbar-collapse" id="navbarSupportedContent">


      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">


        <li class="nav-item">
          <router-link to="/" class="nav-link active" aria-current="page">Home</router-link>
        </li>


         <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Menu
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          	<li><router-link to="/all-menu" class="dropdown-item"> View All </router-link></li>
            <li v-for="c in categories"><router-link :to=" { name : 'menu-items', params : { id : c.id } } " class="dropdown-item"> {{c.name}} </router-link></li>
            <!--<li><router-link to="/custom-meals" class="dropdown-item"> Custom Meals </router-link></li>-->
            
          </ul>
        </li>

          <li class="nav-item">
          <router-link to="/how-it-works" class="nav-link" aria-current="page">How It Works</router-link>
        </li>

         <li class="nav-item">
          <router-link to="/contact" class="nav-link" aria-current="page">Contact Us</router-link>
        </li>

         <li class="nav-item">
          <router-link to="/faq" class="nav-link" aria-current="page">FAQ</router-link>
        </li>

     

      </ul>




      <div class="d-flex">
      	<router-link to="/cart" class="btn"> <i class="bi bi-cart"></i> <sup v-if="this.$store.state.final_total_qty" class="color-1"> {{this.$store.state.final_total_qty}} </sup> <sup v-if="!this.$store.state.final_total_qty">0</sup> </router-link>
        <router-link class="btn btn-1" to="/track-order"> <i class="bi bi-truck"></i> Track Order</router-link>
      </div>

    </div>
  </div>
</nav>

</template>


<script>
	import axios from 'axios'
	export default{
		name : 'header',
		data(){
			return{
				categories : [],
        is_mobile : false
			}
		},

		methods : {

			async product_categories(){

				const res = await axios.get(this.$store.state.url+'api/list-categories').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.categories = res 
			}

		},

		created(){
			this.product_categories()
      //this.is_mobile = navigator.userAgentData.mobile;

		}
	}

</script>

