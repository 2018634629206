<template>
	<Header />



	<section style="padding-top:50px; padding-bottom: 100px;">
		

		<div style="text-align:center;">
			
				

			<div class="mb-3" v-if="this.$store.state.is_package_selected!='yes' ">
				<h1> <i class="bi bi-info-circle color-1"></i> </h1>
					
					<h5><span class="color-3">You have not selected a package yet</span><br>
				<b>Select a package below</b></h5>

				<div>
					<button @click="select_package(p.qty,p.id)" class="btn btn-1 package-btn" v-for="p in packages"> {{ p.qty }} </button>
				</div>

				</div>



		</div>


	</section>


	<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'home',
		components : { Header,Footer },
		data(){
			return{
				packages : []
			}
		},
		methods : {

			select_package(p,id){
				localStorage.setItem('is_package_selected','yes')
				localStorage.setItem('selected_package',p)
				localStorage.setItem('package_id',id)
				this.$store.state.is_package_selected = 'yes'
				this.$store.state.selected_package = p
				this.$store.state.package_id = id 

				Swal.fire({
					  title: "Success",
					  icon: "success",
					  text: "Package selected successfully.You can now add items to cart",
					  showConfirmButton: true,
					 
					})

				this.$router.push('/all-menu')
			},

				async get_packages(){

				const res = await axios.get(this.$store.state.url+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.packages = res 

			},

		},

		created(){

			if(this.$store.state.is_package_selected!='yes'){
				this.$router.push('/choose-package')
			}else{
			this.$router.push('/all-menu')	
			}

			this.get_packages()

			
		}
	}

</script>