<template>
	<Header />



	<section style="padding-top:100px; padding-bottom: 100px;">
		

		<div style="text-align:center;">
			
			<h3 class="color-3">MENU</h3>
			<h1> CHOOSE FROM A VARIETY OF MEALS</h1>

			<p>
			From breakfast to dinner, we got you covered.
			</p>



			<div class="container" style="padding-top:40px;">


				<div class="row">


					<div class="col-md-3 mt-3" v-for="c in categories">
				
				<router-link :to=" { name : 'menu-items', params : { id : c.id } } " style="text-decoration: none;">
					<div class="categories" :style=" { 'backgroundImage' : 'url('+this.$store.state.url+'assets/product-category-images/'+c.image+')' } ">
					<h1><b>{{c.name}}</b></h1>
					</div>	
				</router-link>
				

			</div>

			<!--
				<div class="col-md-3 mt-3">
				
				<router-link to="/custom-meals" style="text-decoration: none;">
					<div class="categories" style="background-image: url('/images/collage-assortment-dishes-from-different-countries-world-food-snacks_187166-69374.jpeg');">
					<h1><b>CUSTOM MEALS</b></h1>
					</div>	
				</router-link>
				

			</div>-->

			
					
				</div>
				
			</div>

		</div>


	</section>


	<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	export default{
		name : 'home',
		components : { Header,Footer },
		data(){
			return{
				categories : []
			}
		},
		methods : {

				async product_categories(){

				const res = await axios.get(this.$store.state.url+'api/list-categories').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.categories = res 
			}

		},

		created(){

			if(this.$store.state.is_package_selected!='yes'){
				this.$router.push('/choose-package')
			}else{
			this.product_categories()	
			}

			
		}
	}

</script>