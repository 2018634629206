<template>
	<Header />



	<section style="padding-top:100px;">
		

		<div style="text-align:center;">
			
			<h3 class="color-3">MENU</h3>
			<h1>CHOOSE FROM A VARIETY OF MEALS</h1>

			<p>
			From breakfast to dinner, we got you covered.
			</p>


			<div class="mb-3" v-if="this.$store.state.is_package_selected!='yes' ">
					
					<p><span class="color-3">You have not selected a package yet</span><br>
				<b>Select a package below</b></p>

				<div>
					<button @click="select_package(p.qty,p.id)" class="btn btn-1 package-btn" v-for="p in packages"> {{ p.qty }} </button>
				</div>

				</div>
		

		</div>


	</section>


	<section>
		<div class="container">

			<div class="row">

				<div class="col-md-3 mt-3" v-for="i in items">

					<div class="shadow">
						
						<div :style=" { 'backgroundImage' : 'url('+this.$store.state.url+'assets/product-images/'+i.image+')', 'width' : '100%', 'height' : '200px', 'backgroundSize' : 'cover', 'backgroundPosition' : 'center' } "></div>

						<div class="p-1" style="min-height: 60px;">
							<h6> {{i.name}} </h6>
						</div>


						<div class="row p-1">

							<div class="col-md-6">
								<h5 class="color-1"><b>Ksh.{{i.price}}</b> </h5>
							</div>


							<div class="col-md-6" style="text-align:right;">

								<p> <i class="bi bi-check color-1"></i> {{ i.category }} </p>
								
							</div>
							
						</div>


						<div class="row p-1">

							<div class="col-md-6 col-6">
								<router-link :to=" { name : 'product-details', params : { product_number : i.product_number } } " class="btn btn-3 w-100">View</router-link>
							</div>


							<div class="col-md-6 col-6" style="text-align:right;">
								<button @click="add_to_cart(i.product_number,i.name,i.price)" class="btn btn-2 w-100">Add to cart</button>
							</div>
							
						</div>

					</div>
					
				</div>

				
			</div>
			
		</div>

	</section>


	<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'home',
		components : { Header,Footer },
		data(){
			return{
				items : [],
				id : '',
				packages : [],
				qty : 1
			}
		},

		methods : {

			add_to_cart(product_number,name,price){

				if(this.$store.state.is_package_selected!='yes'){

				Swal.fire({
					  title: "Please note",
					  icon: "info",
					  text: "You must select a package first before adding a meal to cart",
					  showConfirmButton: true,
					 
					})
				return
				}

				let item  = { 'product_number' : product_number, 'name' : name, 'price' : price, 'qty' : this.qty }

												
				if( ( parseInt(this.$store.state.final_total_qty) + parseInt(this.qty) ) > parseInt(this.$store.state.selected_package) ){
					Swal.fire({
					  title: "Error",
					  text: "Items in cart exceeds your package size",
					  icon: "error"
					})

					}else{
				this.$store.state.cart = this.$store.state.cart.concat(item)
				localStorage.setItem('cart',JSON.stringify(this.$store.state.cart))

				this.$store.state.final_total_qty = parseInt(this.$store.state.final_total_qty) + parseInt(this.qty)

				localStorage.setItem('final_total_qty',this.$store.state.final_total_qty)
				
				Swal.fire({
				  title: "Success",
				  text : 'Item added to cart',
				  icon : 'success',
				  showDenyButton: true,
				  showCancelButton: true,
				  confirmButtonText: "View cart",
				  denyButtonText: `Continue shopping`
				}).then((result) => {
				  /* Read more about isConfirmed, isDenied below */
				  if (result.isConfirmed) {
				    this.$router.push('/cart')
				  } else if (result.isDenied) {
				    //pass
				  }
				})

				
					}
					this.qty = 1

			},



			select_package(p,id){
				localStorage.setItem('is_package_selected','yes')
				localStorage.setItem('selected_package',p)
				localStorage.setItem('package_id',id)
				this.$store.state.is_package_selected = 'yes'
				this.$store.state.selected_package = p
				this.$store.state.package_id = id 

				Swal.fire({
					  title: "Success",
					  icon: "success",
					  text: "Package selected successfully.You can now add items to cart",
					  showConfirmButton: true,
					 
					})
			},

				async get_packages(){

				const res = await axios.get(this.$store.state.url+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.packages = res 

			},

				async menu_items(){

				const res = await axios.get(this.$store.state.url+'api/menu-items/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.items = res 
			}
		},
		created(){
			this.id = this.$route.params.id 


			if(this.$store.state.is_package_selected!='yes'){
				this.$router.push('/choose-package')
			}


			this.menu_items()
			this.get_packages()

			if(!this.$store.state.final_total_qty){
				this.$store.state.final_total_qty = 0 
			}
		}

	}

</script>